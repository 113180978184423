import api from './api'

export default {
  criarSala(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/votacao/criarsala', body, config)
  },
  cadastrar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    };
    return api().post('/utilizacaoWhatsapp/cadastrarUtilizacaoWhatsapp', body, config)
  },
  buscar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post(`/utilizacaoWhatsapp/buscarUtilizacaoWhatsapp/${body}`, config)
  },
  buscarSalaToken(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/buscarsalaToken', body, config)
  },

  votar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/votar', body, config)
  },

  finalizarVotacao(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/finalizarVotacao', body, config)
  },

  adicionaratt(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/adicionarAtividade', body, config)
  },

  deletar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/deletarUsuario', body, config)
  },

  reiniciar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
    };
    return api().post('/votacao/reiniciar', body, config)
  }
}