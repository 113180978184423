<template>
  <v-container fill-height fluid grid-list-xl>
    <div v-if="!dadosUtilizacaoWhatsappCliente">
      <p>Carregando...</p>
    </div>
    <v-layout justify-center wrap v-if="dadosUtilizacaoWhatsappCliente">
      <div v-if="(dadosUtilizacaoWhatsappCliente.length === 0)">
        <p>Nenhuma Utilização Whatsapp...</p>
      </div>
      <v-flex md12 v-if="(dadosUtilizacaoWhatsappCliente.length > 0)">
        <v-card class="pa-2">
          <!-- Título -->
          <v-toolbar color="light-blue" class="ma-2"
            style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
            <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title"> 
                Utilização Whatsapp
              </span> 

            </div>
          </v-toolbar>
          <br>
          <!-- Filtro -->
          <!-- <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:center;padding-left:10%;padding-right:10%;">
            <v-col cols="12" md="3" >
                    <v-select class="" label="Status" v-model="filtroStatusPagto" :items="listadestatus"
                        item-value="id" item-text="nome" return-object style="width:100%;" >
                    </v-select></v-col>
            <v-col cols="12" md="3" >
                    <v-text-field class="mr-2" type="date" label="Check-IN Inicial" v-model="filtroDataInicial" style="width:100%;"  >
                    </v-text-field>
            </v-col>
            <v-col cols="12" md="3" >
                    <v-text-field class="mr-2" type="date" label="Check-IN Final" v-model="filtroDataFinal" style="width:100%;" >
                    </v-text-field>
            </v-col>
            <v-col cols="12" md="3" >
                    <v-btn @click="loadConsumo()" small color="primary" class="mx-2" :disabled="textoBtnPequisar != 'Pesquisar'" style="color:white;min-height:40px;font-weight:bold;width:100%;">
                        <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                        {{textoBtnPequisar}}
                    </v-btn>
            </v-col>


      
          </v-row> -->
          <v-spacer />

          
          
          <v-data-table  :headers="headersSintetico" :items="dadosUtilizacaoWhatsappCliente"   class="elevation-1" >
            <template v-slot:[`item.data_final`]="{ item }">
                 {{item.data_final | formatarData}}
                    </template>
                    <template v-slot:[`item.data_pagamento`]="{ item }">
                 {{item.data_pagamento | formatarData}}
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn v-if="(item.data_pagamento === null || item.valor_pagamento === null)" small elevation="" class="ml-2" @click="() => exibirModalRealizarPagamento = true">
                       <img class="pagamentoButton" src="/whatsapp/pagamento.png" alt="imgpagamento" />
                      </v-btn>
                      <v-icon v-else color="">mdi-check</v-icon>
                  </template>
          </v-data-table>
          <v-dialog v-model="exibirModalRealizarPagamento" width="80vw" max-width="500px">
            <div style="background-color:white" class="containerInputsPagamento">
              <h3>Realizar pagamento</h3>
              <input class="inputText" v-model="valor_pagamento" type="text" placeholder="R$"/>
            
                <v-text-field type="date" label="Data de pagamento"
                class="inputData" 
                 v-model="data_pagamento" outlined 
                 >
                </v-text-field>
                <div>
                  <button class="button" type="button" @click="concluirPagamento">Concluir</button>
                  <button class="button" type="button" @click="() => this.exibirModalRealizarPagamento = false">Cancelar</button>
                </div>
            </div>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import whatsapp from '../services/whatsapp.service';
import dayjs from 'dayjs';
import axios from 'axios'
export default {
  name: 'whatsapp',
  data() {
    return {
    headersSintetico: [
      { text: "Cliente", value: "nome" },
      { text: "Competencia", value: "data_final" },
      { text: "Valor mensagem", value: "valor_mensagem" },
      { text: "Valor Somado", value: "valor_total_mensagem" },
      { text: "Data Pagamento", value: "data_pagamento" },
      { text: "Valor Pagamento", value: "valor_pagamento" },
      { text: "Ações", value: "action" },
    ],
    idcliente: null,
    dadosUtilizacaoWhatsappCliente: null,
    exibirModalRealizarPagamento: false,
    data_pagamento: null,
    valor_pagamento: null,
    }
  },
  methods: {
   async buscarClienteById() {
    await whatsapp.buscar(this.idcliente)
      .then((res) => {
        this.dadosUtilizacaoWhatsappCliente = res.data;
        console.log(this.dadosUtilizacaoWhatsappCliente)
      })
      .catch((error) => {
        console.log(error);
      })
    },

    async concluirPagamento() {
      const data = {
        data_pagamento: this.data_pagamento,
        idcontrato: this.dadosUtilizacaoWhatsappCliente[0].idcontrato,
        valor_pagamento: this.valor_pagamento
      }
     await axios.post('http://localhost:3335/whatsapp/realizarPagamento', data, {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
      })
      .then(() => {
        this.exibirModalRealizarPagamento = false
      })
      .catch((error) => {
        alert('Erro ao realizar o pagamento', error)
      })

      let dadosAtualiados;
      //buscar dados atualizados
      await axios.post('http://localhost:3335/whatsapp/buscarDadosUtilizacao', {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}` }
      })
      .then((resp) => {
        dadosAtualiados = {
            idhotel: this.$route.params.idcliente,
            data_inicio: resp.data[0].data_inicio,
            data_final: resp.data[0].data_final,
            data_pagamento: resp.data[0].data_pagamento,
            valor_total_mensagem: resp.data[0].valor_total_mensagem,
            valor_mensagem: resp.data[0].valor_mensagem,
            valor_pagamento: resp.data[0].valor_pagamento,
            data_criacao: resp.data[0].data_criacao,
            idcontrato: resp.data[0].idcontrato,
          }
      })
      .catch((error) => {
        alert('Erro ao realizar o pagamento', error)
      })
      
      //atualiar dados

      await whatsapp.cadastrar(dadosAtualiados)
      .then(async () => {
        await this.buscarClienteById();
        console.log('dados atualizados')
      })
      .catch((error) => {
        console.log('erro ao atualizar dados', error)
      })
    }

  },

  async mounted(){
    const { idcliente } = this.$route.params
    this.data_pagamento = dayjs().format('YYYY-MM-DD')
    this.idcliente = idcliente;
    await this.buscarClienteById();
  }
}
</script>

<style scoped>
.pagamentoButton {
  width: 35px;
}

.inputText {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}

.containerInputsPagamento {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.inputData {
  width: 100%;
  margin-top: 30px;
}

.button {
  background-color: #1377F2;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    margin: 10px;
    height: 30px;
}
</style>
